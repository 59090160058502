import { filter } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
// material
import {
  Box,
  Button,
  Card,
  Chip,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  LinearProgress
} from '@mui/material';
// components
import axios from 'axios';
import Iconify from '../components/Iconify';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { RankingListHead, RankingListToolbar } from '../sections/@dashboard/ranking';
// mock
import { CORS_PROXY, CRYPTO_LIST } from '../_mock/env';
import L from '../utils/locale';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'rank', label: 'ranking', alignRight: false },
  { id: 'pair', label: 'symbol', alignRight: false },
  { id: 'lastPrice', label: 'price', alignRight: false },
  { id: 'priceChangePercent', label: 'percentage', alignRight: false },
  { id: 'crowdIndex', label: 'crowdIndex', alignRight: false },
  { id: 'fundingRate', label: 'fundingRate', alignRight: true },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Ranking() {
  const [lang] = useOutletContext();

  const [cryptoList, setCryptoList] = useState(CRYPTO_LIST);

  const [tickers, setTickers] = useState([]);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('crowdIndex');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = cryptoList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - cryptoList.length) : 0;

  const filteredUsers = useMemo(
    () => applySortFilter(cryptoList, getComparator(order, orderBy), filterName),
    [cryptoList, filterName, order, orderBy]
  );

  const filteredCryptoList = useMemo(
    () => applySortFilter(cryptoList, getComparator('asc', 'crowdIndex'), ''),
    [cryptoList]
  );

  const longPairPriceChangePercent = useMemo(
    () =>
      tickers.find((pair) => pair.symbol === filteredCryptoList[0].pair)
        ? tickers.find((pair) => pair.symbol === filteredCryptoList[0].pair).priceChangePercent
        : 0,
    [tickers, filteredCryptoList]
  );

  const shortPairPriceChangePercent = useMemo(
    () =>
      tickers.find((pair) => pair.symbol === filteredCryptoList[filteredCryptoList.length - 1].pair)
        ? tickers.find((pair) => pair.symbol === filteredCryptoList[filteredCryptoList.length - 1].pair)
            .priceChangePercent
        : 0,
    [tickers, filteredCryptoList]
  );

  const estimateProfit = useMemo(
    () => Number(longPairPriceChangePercent) - Number(shortPairPriceChangePercent),
    [longPairPriceChangePercent, shortPairPriceChangePercent]
  );

  const isUserNotFound = filteredUsers.length === 0;

  const batchPlaceOrder = async () => {
    const { data } = await axios.post(
      'https://gateway.ritmex.one/batch_place_order',
      {
        buy_pair: filteredCryptoList[0].pair,
        buy_position: 1,
        sell_pair: filteredCryptoList[filteredCryptoList.length - 1].pair,
        sell_position: 1,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );
    if ('error' in data) {
      // eslint-disable-next-line no-alert
      alert(data.error);
    } else {
      // eslint-disable-next-line no-alert
      alert(`Success!`);
    }
  };

  useEffect(() => {
    async function fetchData() {
      const binanceTickers = await axios(`${CORS_PROXY}https://fapi.binance.com/fapi/v1/ticker/24hr`);

      setTickers(binanceTickers.data);

      const fundingRate = await axios(`${CORS_PROXY}https://fapi.binance.com/fapi/v1/premiumIndex`);

      cryptoList.map(async (symbol) => {
        const crowdInfo = await axios(
          `${CORS_PROXY}https://fapi.binance.com/futures/data/globalLongShortAccountRatio?symbol=${symbol.pair}&period=1h&limit=1`
        );

        const crowdIndex = crowdInfo.data[0].longAccount * 100;

        const nextList = [...cryptoList];
        const newSymbol = nextList.find((a) => a.name === symbol.name);
        newSymbol.crowdIndex = crowdIndex;
        newSymbol.lastPrice = Number(binanceTickers.data.find((ticker) => ticker.symbol === symbol.pair).lastPrice);
        newSymbol.priceChangePercent = Number(
          binanceTickers.data.find((ticker) => ticker.symbol === symbol.pair).priceChangePercent
        );
        newSymbol.fundingRate = Number(fundingRate.data.find((funding) => funding.symbol === symbol.pair).lastFundingRate);
        setCryptoList(nextList);
      });
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const overBoughtIndex = cryptoList.filter((crypto) => Number(crypto.priceChangePercent) > 0).length / cryptoList.length * 100;

  return (
    <Page title="Ranking">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {L.ranking[lang]}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
          <Typography variant="p">
            {L.overBoughtIndex[lang]}
          </Typography>
            <Box width={'8rem'}>
              <LinearProgress color={overBoughtIndex > 50 ? 'error' : 'success'} variant="determinate" value={overBoughtIndex} />
            </Box>
            <Box display={{
              xs: 'none',
              md: 'flex',
            }} justifyContent={'center'} alignItems={'center'} gap={2}>
            <Box>
              <Button variant="contained" onClick={batchPlaceOrder}>
                {L.oneClickHedge[lang]}
              </Button>
            </Box>
            <Box>
              <Typography variant="body2">{filteredCryptoList[0].pair}</Typography>
              <Chip
                label={`${Number(filteredCryptoList[0].crowdIndex).toFixed(2)}%`}
                size="small"
                color={Number(filteredCryptoList[0].crowdIndex) < 50 ? 'success' : 'warning'}
              />
            </Box>
            <Box>
              <Typography variant="body2">{filteredCryptoList[filteredCryptoList.length - 1].pair}</Typography>
              <Chip
                label={`${Number(filteredCryptoList[filteredCryptoList.length - 1].crowdIndex).toFixed(2)}%`}
                size="small"
                color={Number(filteredCryptoList[0].crowdIndex) < 50 ? 'error' : 'warning'}
              />
            </Box>
            <Box>
              <Typography variant="body2">{L.profit24[lang]}</Typography>
              <Chip
                label={`${estimateProfit.toFixed(2)}%`}
                size="small"
                color={estimateProfit > 0 ? 'success' : 'error'}
              />
            </Box>
            </Box>
          </Box>
        </Stack>

        <Card>
          <RankingListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <RankingListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={cryptoList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.length > 0 &&
                    filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                      const { name, pair, lastPrice, crowdIndex, priceChangePercent } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;

                      return (
                        <TableRow
                          hover
                          key={name}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, name)} />
                        </TableCell> */}
                          <TableCell component="th" scope="row" padding="normal">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {index + 1}
                              </Typography>
                              <Iconify icon={`cryptocurrency-color:${name}`} width={24} height={24} />
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{pair}</TableCell>
                          <TableCell align="left">
                            <Typography variant="body2">${lastPrice}</Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              variant="body2"
                              sx={{
                                color: Number(priceChangePercent) > 0 ? '#66bb6a' : '#f44336',
                              }}
                            >
                              {Number(priceChangePercent).toFixed(2)}%
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              variant="body2"
                              sx={{
                                color: (Number(crowdIndex) < 50 && '#66bb6a') || (Number(crowdIndex) > 60 && '#f44336'),
                              }}
                            >
                              {Number(crowdIndex).toFixed(2)}%
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            {Number(row.fundingRate*100).toFixed(4)}%
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={cryptoList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
