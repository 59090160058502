import PropTypes from 'prop-types';
// material
import { Grid } from '@mui/material';
import ApiCard from './ApiCard';

// ----------------------------------------------------------------------

ApiList.propTypes = {
  lang: PropTypes.string,
  apis: PropTypes.array.isRequired,
  setLoading: PropTypes.func,
  tvForm: PropTypes.object,
  handleFormInput: PropTypes.func,
};

export default function ApiList({ lang, apis, setLoading, ...other }) {
  return (
    <Grid container spacing={3} {...other}>
      {apis.map((api) => (
        <Grid key={api.api_key} item xs={12} sm={6} md={3}>
          <ApiCard lang={lang} api={api} setLoading={setLoading} />
        </Grid>
      ))}
    </Grid>
  );
}
