import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container } from '@mui/material';
// components
import Page from '../components/Page';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Terms() {
  return (
    <Page title="RitMEX Terms of Service">
      <Container>
        <ContentStyle sx={{ textAlign: 'left', alignItems: 'left' }}>
          <Typography variant="h3" paragraph>
            RitMEX Terms of Service
          </Typography>

          <Typography variant="caption" paragraph>
            Effective date: September 1, 2022
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 2 }}>
            Thank you for using RitMEX! We're happy you're here. Please read this Terms of Service agreement carefully
            before accessing or using RitMEX. Because it is such an important contract between us and our users, we have
            tried to make it as clear as possible. For your convenience, we have presented these terms in a short
            non-binding summary followed by the full legal terms.
          </Typography>

          <Button to="/" size="large" variant="contained" component={RouterLink}>
            Go to Home
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  );
}
