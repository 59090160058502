const L = {
  login: {
    en: 'Login',
    cn: '登录',
  },
  pair: {
    en: 'Pair',
    cn: '交易对',
  },
  chart: {
    en: 'Chart',
    cn: '图表',
  },
  fng: {
    en: 'Fear & Greed Index',
    cn: '恐惧贪婪指数',
  },
  crowdIndex: {
    en: 'Crowd Index',
    cn: '韭菜指数',
  },
  eliteIndex: {
    en: 'Elite Index',
    cn: '精英指数',
  },
  sAndR: {
    en: 'Support & Resistance',
    cn: '阻力 & 支撑',
  },
  mainChartLine: {
    en: 'temporary support and resistance price levels.',
    cn: '短线阻力及支撑价格点位。',
  },
  interestFlow: {
    en: 'Interest Flow',
    cn: '资金流向',
  },
  newsUpdate: {
    en: 'News Update',
    cn: '热点新闻',
  },
  viewAll: {
    en: 'View All',
    cn: '更多',
  },
  es: {
    en: 'Economic Statistics',
    cn: '经济数据',
  },
  fedRate: {
    en: 'United States Fed Funds Rate',
    cn: '美联储利率',
  },
  indicator: {
    en: 'Indicator',
    cn: '指标',
  },
  value: {
    en: 'Value',
    cn: '数值',
  },
  status: {
    en: 'Status',
    cn: '状态',
  },
  analyze: {
    en: 'Analyze',
    cn: '分析',
  },
  taTitle: {
    en: 'A.I. Technical Analysis',
    cn: '人工智能行情分析',
  },
  buy: {
    en: 'Buy',
    cn: '购买',
  },
  dashboard: {
    en: 'Dashboard',
    cn: '数据面板',
  },
  strategy: {
    en: 'Strategy',
    cn: '策略',
  },
  setting: {
    en: 'Settings',
    cn: '设置',
  },
  back: {
    en: 'Back',
    cn: '上一步',
  },
  next: {
    en: 'Next',
    cn: '下一步',
  },
  finish: {
    en: 'Finished',
    cn: '已完成',
  },
  permanent: {
    en: 'Lifetime',
    cn: '永久',
  },
  threeMonth: {
    en: '3 Months',
    cn: '3 个月',
  },
  halfyear: {
    en: '6 Months',
    cn: '半年',
  },
  okxDiscount: {
    en: 'OKX discount',
    cn: 'OKX 优惠',
  },
  binanceDiscount: {
    en: 'Binance discount',
    cn: 'Binance 优惠',
  },
  bitgetDiscount: {
    en: 'Bitget discount',
    cn: 'Bitget 优惠',
  },
  donate: {
    en: 'Donate',
    cn: '赞助',
  },
  ranking: {
    en: 'Ranking',
    cn: '排行',
  },
  symbol: {
    en: 'Symbol',
    cn: '交易对',
  },
  percentage: {
    en: '24h Change',
    cn: '24h 涨跌',
  },
  profit24: {
    en: '24h Profit',
    cn: '24h 收益',
  },
  price: {
    en: 'Price',
    cn: '最新价格',
  },
  longTrend: {
    en: 'Long Trend',
    cn: '多头趋势',
  },
  shortSqueeze: {
    en: 'Short Squeeze',
    cn: '空头扛单',
  },
  shortStopLoss: {
    en: 'Short Stop Loss',
    cn: '空头止损',
  },
  longTakeProfit: {
    en: 'Long Take Profit',
    cn: '多头止盈',
  },
  longSqueeze: {
    en: 'Long Squeeze',
    cn: '多头扛单',
  },
  shortTrend: {
    en: 'Short Trend',
    cn: '空头趋势',
  },
  shortTakeProfit: {
    en: 'Short Take Profit',
    cn: '空头止盈',
  },
  longStopLoss: {
    en: 'Long Stop Loss',
    cn: '多头止损',
  },
  apis: {
    en: 'APIs',
    cn: 'APIs',
  },
  confirm: {
    en: 'Confirm',
    cn: '确认',
  },
  check: {
    en: 'Check',
    cn: '查看',
  },
  calendar: {
    en: 'Calendar',
    cn: '日历',
  },
  oneClickHedge: {
    en: 'One Click Hedge',
    cn: '一键对冲',
  },
  trade: {
    en: 'Trade',
    cn: '交易',
  },
  delete: {
    en: 'Delete',
    cn: '删除',
  },
  side: {
    en: 'Side',
    cn: '方向',
  },
  sell: {
    en: 'Sell',
    cn: '卖出',
  },
  placeOrder: {
    en: 'Place Order',
    cn: '下单',
  },
  position: {
    en: 'Position',
    cn: '仓位',
  },
  openOrders: {
    en: 'Open Orders',
    cn: '未成交订单',
  },
  orderPrice: {
    en: 'Order Price',
    cn: '订单价格',
  },
  instId: {
    en: 'Instrument ID',
    cn: '合约代码',
  },
  instType: {
    en: 'Instrument Type',
    cn: '合约类型',
  },
  avgPx: {
    en: 'Average Price',
    cn: '成交均价',
  },
  posSide: {
    en: 'Position Side',
    cn: '持仓方向',
  },
  pos: {
    en: 'Position',
    cn: '持仓量',
  },
  uplRatio: {
    en: 'Unrealized P&L Ratio',
    cn: '未实现盈亏比例',
  },
  time: {
    en: 'Time',
    cn: '时间',
  },
  action: {
    en: 'Action',
    cn: '操作',
  },
  ordType: {
    en: 'Order Type',
    cn: '订单类型',
  },
  size: {
    en: 'Size',
    cn: '数量',
  },
  cancel: {
    en: 'Cancel',
    cn: '撤单',
  },
  close: {
    en: 'Close',
    cn: '平仓',
  },
  exchangeAPIConnection: {
    en: 'Exchange API Connection',
    cn: '交易所 API 连接',
  },
  exchange: {
    en: 'Exchange',
    cn: '交易所',
  },
  strategyDetails: {
    en: 'Strategy Details',
    cn: '策略详情',
  },
  stoploss: {
    en: 'Stop Loss',
    cn: '止损',
  },
  takeprofit: {
    en: 'Take Profit',
    cn: '止盈',
  },
  saveStrategy: {
    en: 'Save Strategy',
    cn: '保存策略',
  },
  strategyLinks: {
    en: 'Strategy Links',
    cn: '策略链接',
  },
  tradingInfo: {
    en: 'Trading Info',
    cn: '交易信息',
  },
  exchangeDetails: {
    en: 'Exchange Details',
    cn: '交易所详情',
  },
  ritmexTrade: {
    en: 'RitMEX Trade',
    cn: 'RitMEX 交易',
  },
  selectedDefi: {
    en: 'Selected DeFi',
    cn: 'DeFi 精选',
  },
  overBoughtIndex: {
    en: 'OB Index',
    cn: '超买指数',
  },
  home: {
    en: 'Home',
    cn: '首页',
  },
  wallet: {
    en: 'Wallet',
    cn: '钱包',
  },
  fundingRate: {
    en: 'Funding Rate',
    cn: '资金费率',
  },
  '': {
    en: '',
    cn: '',
  },
};

// {L.pair[lang]}

export default L;
