import { useState } from 'react';
import PropTypes from 'prop-types';
// material
import { Grid } from '@mui/material';
import IndicatorCard from './IndicatorCard';

// ----------------------------------------------------------------------

IndicatorList.propTypes = {
  lang: PropTypes.string,
  indicators: PropTypes.array.isRequired,
  tvForm: PropTypes.object,
  handleFormInput: PropTypes.func,
};

export default function IndicatorList({ lang, indicators, ...other }) {
  const initName = localStorage.getItem('name') ? localStorage.getItem('name') : '';
  const [tvForm, setTvForm] = useState({ tvName: initName, tvNameConfirm: initName });

  const handleFormInput = (event) => {
    setTvForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <Grid container spacing={3} {...other}>
      {indicators.map((indicator) => (
        <Grid key={indicator.id} item xs={12} sm={6} md={3}>
          <IndicatorCard lang={lang} indicator={indicator} tvForm={tvForm} handleFormInput={handleFormInput} />
        </Grid>
      ))}
    </Grid>
  );
}
