import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Page from '../components/Page';
import { ExchangeForm, ResultForm, TradeForm } from '../sections/@dashboard/strategy';
import MessageSnackbar from '../sections/auth/MessageSnackbar';
import L from '../utils/locale';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © RitMEX '}
      {new Date().getFullYear()}
      {'.'}{' '}
      <Link color="inherit" href="#">
        Terms of Service
      </Link>
    </Typography>
  );
}

const steps = ['Exchange Connection', 'Strategy details', 'Link to Tradingview'];

function getStepContent(step, account, strategy, handleExchangeForm, handleTradeForm) {
  switch (step) {
    case 0:
      return <ExchangeForm account={account} handleExchangeForm={handleExchangeForm} />;
    case 1:
      return <TradeForm strategy={strategy} handleTradeForm={handleTradeForm} />;
    case 2:
      return <ResultForm account={account} strategy={strategy} />;
    default:
      throw new Error('Unknown step');
  }
}

const defaultAccount = {
  apiKey: '',
  apiSecret: '',
  passPhrase: '',
  exchange: 'okx',
  token: localStorage.getItem('token'),
};

const defaultStrategy = {
  pair: 'BTCUSDT',
  stoploss: 0,
  takeprofit: 0,
  position: 0.1,
};

export default function Strategy() {
  const navigate = useNavigate();

  const [lang] = useOutletContext();

  const [activeStep, setActiveStep] = useState(0);

  const [account, setAccount] = useState(defaultAccount);

  const [strategy, setStrategy] = useState(defaultStrategy);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const handleExchangeForm = (event) => {
    setAccount((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleTradeForm = (event) => {
    setStrategy((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleNext = async () => {
    if (activeStep === 0) {
      try {
        const store = await axios.post(
          'https://gateway.ritmex.one/store',
          {
            api_key: account.apiKey,
            api_secret: account.apiSecret,
            pass_phrase: account.passPhrase,
            exchange: account.exchange,
            token: localStorage.getItem('token'),
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
        const { token, message } = store.data;
        if (token) {
          setActiveStep(activeStep + 1);
        } else {
          setOpen(true);
          setMessage(message);
        }
      } catch (err) {
        if (err.response.data.errors && Object.prototype.hasOwnProperty.call(err.response.data.errors, 'api_key')) {
          if (
            err.response.data.errors &&
            err.response.data.errors.api_key[0] === 'The api key has already been taken.' &&
            !Object.prototype.hasOwnProperty.call(err.response.data.errors, 'pass_phrase')
          ) {
            setActiveStep(activeStep + 1);
          } else {
            setOpen(true);
            setMessage(err.response.data.message);
          }
        } else {
          setOpen(true);
          setMessage(err.response.data.message);
        }
      }
    } else if (activeStep < steps.length - 1) {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    async function checkLogin() {
      const isLogin = await axios.post(
        'https://gateway.ritmex.one/check',
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (!isLogin.data) {
        navigate('/login', { replace: true });
      }
    }
    checkLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title="Dashboard: Strategy">
      <Container component="main" sx={{ mb: 4 }}>
        <Typography variant="h4" sx={{ mb: 5 }}>
          {L.strategy[lang]}
        </Typography>

        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography component="h1" variant="h4" align="center">
            {L.setting[lang]}
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <>
            {activeStep === steps.length ? (
              <>
                <Typography variant="h5" gutterBottom>
                  Thank you using RitMEX strategy service.
                </Typography>
                <Typography variant="subtitle1">
                  Your service number is #2001539. We have emailed your service confirmation.
                </Typography>
              </>
            ) : (
              <>
                {getStepContent(activeStep, account, strategy, handleExchangeForm, handleTradeForm)}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                      {L.back[lang]}
                    </Button>
                  )}

                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 3, ml: 1 }}
                    disabled={activeStep === steps.length - 1}
                  >
                    {activeStep === steps.length - 1 ? L.finish[lang] : L.next[lang]}
                  </Button>

                  <MessageSnackbar open={open} message={message} handleClose={handleClose} />
                </Box>
              </>
            )}
          </>
        </Paper>
        <Copyright />
      </Container>
    </Page>
  );
}
