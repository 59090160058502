import { Box, Button, CardActions } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import PropTypes from 'prop-types';
import L from '../../../utils/locale';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

ApiModal.propTypes = {
  lang: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  api: PropTypes.object,
  setLoading: PropTypes.func,
};

export default function ApiModal({ lang, open, handleClose, api, setLoading }) {
  const { api_key: apiKey, exchange } = api;

  const deleteAPI = async () => {
    setLoading(true);
    const { data } = await axios.post(
      'https://gateway.ritmex.one/delete_api',
      {
        // eslint-disable-next-line camelcase
        api_key: apiKey,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );
    if ('error' in data) {
      console.error(data.error);
    } else {
      console.info(`Delete Success!`);
    }
    setLoading(false);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Card sx={{ maxWidth: 600 }}>
            <CardContent>
              <Typography gutterBottom variant="h5">
                {exchange}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {apiKey}
              </Typography>
            </CardContent>
            <CardActions>
              <Button variant="contained" size="medium" color="primary" onClick={handleClose}>
                {L.confirm[lang]}
              </Button>
              <Button variant="contained" size="medium" color="error" onClick={deleteAPI}>
                {L.delete[lang]}
              </Button>
            </CardActions>
          </Card>
        </Box>
      </Modal>
    </div>
  );
}
