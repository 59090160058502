import PropTypes from 'prop-types';
// @mui
import { Box, Card, CardHeader } from '@mui/material';
// components
import { EconomicCalendar } from 'react-ts-tradingview-widgets';
import L from '../../../utils/locale';

// ----------------------------------------------------------------------

AppTradingviewCalendar.propTypes = {
  lang: PropTypes.string,
};

export default function AppTradingviewCalendar({ lang, ...other }) {
  return (
    <Card {...other}>
      <CardHeader title={L.calendar[lang]} />

      <Box sx={{ p: 2, pb: 4 }} dir="ltr">
        <EconomicCalendar
          colorTheme="dark"
          isTransparent
          height={400}
          width="100%"
          locale={lang === 'en' ? 'en' : 'zh_CN'}
          currencyFilter={'USD'}
          importanceFilter={'0,1'}
        />
      </Box>
    </Card>
  );
}
