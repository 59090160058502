import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
// material
import { Container, Typography } from '@mui/material';
// components
import axios from 'axios';
import Page from '../components/Page';
import { ApiList } from '../sections/@dashboard/apis';
// mock
import L from '../utils/locale';

// ----------------------------------------------------------------------

export default function Apis() {
  const [lang] = useOutletContext();

  const [apis, setApis] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchApis() {
      const apisData = await axios.post(
        'https://gateway.ritmex.one/get_apis',
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      setApis(apisData.data);
    }
    if (!loading) {
      fetchApis();
    }
  }, [loading]);

  return (
    <Page title="Dashboard: APIs">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          {L.apis[lang]}
        </Typography>
        <ApiList lang={lang} apis={apis} setLoading={setLoading} />
      </Container>
    </Page>
  );
}
