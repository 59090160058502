import { replace } from 'lodash';
import numeral from 'numeral';

// ----------------------------------------------------------------------

// load a locale
numeral.register('locale', 'cn', {
  delimiters: {
    thousands: ' ',
    decimal: '.',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal: (number) => (number === 1 ? '' : ''),
  currency: {
    symbol: '¥',
  },
});

// switch between locales
numeral.locale('cn');

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return replace(numeral(number).format('0.00a'), '.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}

export function mergeBids(bids, precision) {
  const merged = {};
  bids.forEach(([price, amount]) => {
    const key = Math.floor(Number(price) / precision) * precision;
    merged[key] = (merged[key] || 0) + Number(amount);
  });
  return merged;
}

export function mergeAsks(asks, precision) {
  const merged = {};
  asks.forEach(([price, amount]) => {
    const key = Math.ceil(Number(price) / precision) * precision;
    merged[key] = (merged[key] || 0) + Number(amount);
  });
  return merged;
}
