import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
import L from '../../../utils/locale';

AppPairList.propTypes = {
  lang: PropTypes.string,
  pairs: PropTypes.array,
  current: PropTypes.number,
  handleSelectPair: PropTypes.func,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

export default function AppPairList({ lang, pairs, current, handleSelectPair }) {
  const menuItems = pairs.map((pair, index) => (
    <MenuItem key={pair.pair} value={index}>
      {pair.pair}
    </MenuItem>
  ));
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl sx={{ m: 1, minWidth: 80 }}>
        <InputLabel id="pair-simple-select-label">{L.pair[lang]}</InputLabel>
        <Select
          labelId="pair-simple-select-label"
          id="pair-simple-select"
          value={current}
          label={L.pair[lang]}
          onChange={handleSelectPair}
          MenuProps={MenuProps}
        >
          {menuItems}
        </Select>
      </FormControl>
    </Box>
  );
}
