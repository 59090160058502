import { Box, Card, CardHeader } from '@mui/material';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import axios from 'axios';
import { createChart } from 'lightweight-charts';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import L from '../../../utils/locale';

// ----------------------------------------------------------------------

AppFederalRatesHistory.propTypes = {
  lang: PropTypes.string,
};

export default function AppFederalRatesHistory({ lang, ...other }) {
  const chartContainerRef = useRef();

  useEffect(() => {
    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.parentElement.parentElement.offsetWidth - 20,
      height: 410,
      layout: {
        backgroundColor: '#212b36',
        textColor: 'rgba(255, 255, 255, 0.9)',
      },
      grid: {
        vertLines: {
          color: 'rgba(197, 203, 206, 0)',
        },
        horzLines: {
          color: 'rgba(197, 203, 206, 0)',
        },
      },
      localization: {
        dateFormat: 'yyyy/MM/dd',
        priceFormatter: (percentage) => `${percentage.toFixed(2)}%`,
      },
      timeScale: {
        timeVisible: false,
        secondsVisible: false,
      },
    });

    new ResizeObserver(() => {
      chart.resize(chartContainerRef.current.parentElement.parentElement.offsetWidth - 20, 410);
    }).observe(chartContainerRef.current.parentElement.parentElement);

    async function fetchData() {
      const result = await axios(`https://gateway.ritmex.one/interest_rates`);

      const areaSeries = chart.addAreaSeries({
        topColor: 'rgba(38, 198, 218, 0.56)',
        bottomColor: 'rgba(38, 198, 218, 0.04)',
        lineColor: 'rgba(38, 198, 218, 1)',
        lineWidth: 2,
        symbol: 'US Interest Rates',
      });

      areaSeries.setData(
        result.data
          .map((funding) => ({
            time: funding[0],
            value: funding[1],
          }))
          .reverse()
      );
    }
    fetchData();
    return function cleanChart() {
      chart.remove();
    };
  }, []);

  return (
    <Card {...other}>
      <CardHeader title={L.fedRate[lang]} />
      <Box sx={{ p: 2, pb: 0 }} dir="ltr">
        <div ref={chartContainerRef} />
      </Box>
      <CardActions>
        <Button href="https://tradingeconomics.com/united-states/interest-rate" target="_blank">
          {L.viewAll[lang]}
        </Button>
      </CardActions>
    </Card>
  );
}
