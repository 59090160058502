export const CORS_PROXY = 'https://gateway.ritmex.one/binance/';
export const OKX_PROXY = 'https://gateway.ritmex.one/okx/';

export const CRYPTO_PAIRS = [
  { name: 'btc', pair: 'BTCUSDT', precision: 100, depth: 5000 },
  { name: 'eth', pair: 'ETHUSDT', precision: 10, depth: 5000 },
  { name: 'ltc', pair: 'LTCUSDT', precision: 0.01, depth: 500 },
  { name: 'op', pair: 'OPUSDT', precision: 0.0001, depth: 500 },
  { name: 'apt', pair: 'APTUSDT', precision: 0.001, depth: 1000 },
  { name: 'bnb', pair: 'BNBUSDT', precision: 1, depth: 500 },
  { name: 'doge', pair: 'DOGEUSDT', precision: 0.00001, depth: 500 },
  { name: 'xrp', pair: 'XRPUSDT', precision: 0.0001, depth: 500 },
  { name: 'ada', pair: 'ADAUSDT', precision: 0.0001, depth: 500 },
  { name: 'sol', pair: 'SOLUSDT', precision: 0.01, depth: 500 },
  { name: 'dot', pair: 'DOTUSDT', precision: 0.001, depth: 100 },
  { name: 'matic', pair: 'MATICUSDT', precision: 0.0001, depth: 500 },
  { name: 'trx', pair: 'TRXUSDT', precision: 0.0001, depth: 500 },
  { name: 'link', pair: 'LINKUSDT', precision: 0.001, depth: 500 },
  { name: 'etc', pair: 'ETCUSDT', precision: 0.001, depth: 100 },
  { name: 'eos', pair: 'EOSUSDT', precision: 0.001, depth: 100 },
  { name: 'atom', pair: 'ATOMUSDT', precision: 0.001, depth: 500 },
];

export const CRYPTO_LIST = [
  { rank: 1, name: 'btc', pair: 'BTCUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 2, name: 'eth', pair: 'ETHUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 3, name: 'ltc', pair: 'LTCUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 4, name: 'ada', pair: 'ADAUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 5, name: 'op', pair: 'OPUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 6, name: 'apt', pair: 'APTUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 7, name: 'sand', pair: 'SANDUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 8, name: '1000sats', pair: '1000SATSUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 9, name: 'near', pair: 'NEARUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 10, name: 'fet', pair: 'FETUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 11, name: 'ldo', pair: 'LDOUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 12, name: 'dydx', pair: 'DYDXUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 13, name: 'bnb', pair: 'BNBUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 14, name: 'doge', pair: 'DOGEUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 15, name: 'sol', pair: 'SOLUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 16, name: 'dot', pair: 'DOTUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 17, name: 'matic', pair: 'MATICUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 18, name: 'link', pair: 'LINKUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 19, name: 'etc', pair: 'ETCUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 20, name: 'xrp', pair: 'XRPUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 21, name: 'atom', pair: 'ATOMUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 22, name: 'fil', pair: 'FILUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 23, name: 'sei', pair: 'SEIUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 24, name: 'avax', pair: 'AVAXUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 25, name: 'stx', pair: 'STXUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 26, name: 'bake', pair: 'BAKEUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 27, name: 'cfx', pair: 'CFXUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 28, name: '1000rats', pair: '1000RATSUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 30, name: 'ordi', pair: 'ORDIUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 31, name: 'uni', pair: 'UNIUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 32, name: 'arb', pair: 'ARBUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 33, name: 'id', pair: 'IDUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 34, name: 'wld', pair: 'WLDUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 35, name: 'pixel', pair: 'PIXELUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 36, name: 'xai', pair: 'XAIUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 37, name: 'lpt', pair: 'LPTUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 38, name: 'inj', pair: 'INJUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 39, name: 'manta', pair: 'MANTAUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 40, name: 'sui', pair: 'SUIUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 41, name: 'alt', pair: 'ALTUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 42, name: 'tia', pair: 'TIAUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 43, name: 'render', pair: 'RENDERUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 44, name: 'dym', pair: 'DYMUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 45, name: 'wif', pair: 'WIFUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 46, name: 'w', pair: 'WUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 47, name: 'ena', pair: 'ENAUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
  { rank: 48, name: 'bome', pair: 'BOMEUSDT', lastPrice: 0, priceChangePercent: 0, crowdIndex: 0, funingRate: 0 },
];

export const DEFAULT_LANG = 'en';
