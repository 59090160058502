// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'ranking',
    path: '/dashboard/ranking',
    icon: getIcon('game-icons:rank-3'),
  },
  {
    title: 'indicator',
    path: '/dashboard/indicator',
    icon: getIcon('eva:trending-up-outline'),
  },
  // {
  //   title: 'trade',
  //   path: '/dashboard/trade',
  //   icon: getIcon('eva:swap-fill'),
  // },
  {
    title: 'strategy',
    path: '/dashboard/strategy',
    icon: getIcon('eva:options-2-outline'),
  },
  {
    title: 'apis',
    path: '/dashboard/apis',
    icon: getIcon('ant-design:api-filled'),
  },
];

export default navConfig;
