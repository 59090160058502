import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

MessageSnackbar.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
  handleClose: PropTypes.func,
};

export default function MessageSnackbar({ open, message, handleClose }) {
  const action = (
    <>
      <Button color="error" size="small" onClick={handleClose}>
        DISMISS
      </Button>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} message={message} action={action} />;
}
