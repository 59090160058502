/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//
import axios from 'axios';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';

import { CORS_PROXY, DEFAULT_LANG } from '../../_mock/env';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const userLocale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;

  const defaultLang = userLocale === 'zh-CN' ? 'cn' : DEFAULT_LANG;

  const [open, setOpen] = useState(false);

  const [lang, setLang] = useState(defaultLang);

  const handleSetLang = (lang) => {
    setLang(lang);
  };

  const [pair, setPair] = useState(0);

  const handleChangePair = (value) => {
    setPair(value);
  };

  const [avaliablePairs, setAvaliablePairs] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const exchangeInfo = await axios(`${CORS_PROXY}https://fapi.binance.com/fapi/v1/exchangeInfo`);

      const binancePairsList = exchangeInfo.data.symbols
        .filter((symbol) => symbol.status === 'TRADING' && symbol.contractType === 'PERPETUAL')
        .sort((a, b) => a.onboardDate - b.onboardDate)
        .map((symbol, index) => ({
          name: symbol.baseAsset.toLowerCase(),
          pair: symbol.symbol,
          precision: Number(symbol.filters[0].tickSize),
          depth:
            index < 2
              ? 5000
              : Number(symbol.filters[0].tickSize) >= 0.01
              ? 1000
              : Number(symbol.filters[0].tickSize) >= 0.001
              ? 500
              : 100,
        }));

      const bitcoinIndex = binancePairsList.findIndex((pair) => pair.pair === 'BTCUSDT');

      setAvaliablePairs(binancePairsList);
      setPair(bitcoinIndex);
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RootStyle>
      <DashboardNavbar
        lang={lang}
        avaliablePairs={avaliablePairs}
        handleChangePair={handleChangePair}
        handleSetLang={handleSetLang}
        onOpenSidebar={() => setOpen(true)}
      />
      <DashboardSidebar lang={lang} isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      {avaliablePairs.length > 0 && (
        <MainStyle>
          <Outlet
            context={[lang, pair, avaliablePairs, handleChangePair]}
            pair={pair}
            handleChangePair={handleChangePair}
          />
        </MainStyle>
      )}
    </RootStyle>
  );
}
