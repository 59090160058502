import PropTypes from 'prop-types';
import { useMemo } from 'react';
// material
import { CssBaseline } from '@mui/material';
import { createTheme, StyledEngineProvider, ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
//
import componentsOverride from './overrides';
import palette from './palette';
import shadows, { customShadows, darkCustomShadows, darkShadows } from './shadows';
import typography from './typography';

// ----------------------------------------------------------------------

ThemeProvider.propTypes = {
  mode: PropTypes.string,
  children: PropTypes.node,
};

export default function ThemeProvider({ mode, children }) {
  const themeOptions = useMemo(
    () => ({
      palette:
        mode === 'dark'
          ? {
              mode: 'dark',
              divider: palette.divider,
              background: {
                default: '#161c24',
                paper: '#212b36',
              },
            }
          : palette,
      shape: { borderRadius: 8 },
      typography,
      shadows: mode === 'dark' ? darkShadows : shadows,
      customShadows: mode === 'dark' ? darkCustomShadows : customShadows,
    }),
    [mode]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
