import { useState } from 'react';
import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Grid, Box, CardActions, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Modal from '@mui/material/Modal';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import axios from 'axios';
import { QRCodeSVG } from 'qrcode.react';
import PropTypes from 'prop-types';
import Iconify from '../../../components/Iconify';
import L from '../../../utils/locale';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const qrStyle = {
  height: 150,
  width: 150,
  border: '1px dashed grey',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

IndicatorModal.propTypes = {
  lang: PropTypes.string,
  tvForm: PropTypes.object,
  handleFormInput: PropTypes.func,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  name: PropTypes.string,
  cover: PropTypes.string,
};

const priceList = [299, 99, 199];

export default function IndicatorModal({ lang, tvForm, handleFormInput, open, handleClose, name, cover }) {
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [qrUrl, setQrUrl] = useState('');

  const [period, setPeriod] = useState(0);

  const handleChange = (event) => {
    setLoading(false);
    setPeriod(event.target.value);
    setQrUrl('');
  };

  const handleBuyConfirm = async () => {
    if (tvForm.tvName !== '' && tvForm.tvName === tvForm.tvNameConfirm) {
      setLoading(true);
      setValid(false);
      const payment = await axios.post('https://gateway.ritmex.one/get_code', {
        indicator: name,
        user: tvForm.tvName,
        price: priceList[period],
        period,
      });
      setQrUrl(payment.data.qrCode);
      setLoading(false);

      localStorage.setItem('name', tvForm.tvName);
    } else {
      setValid(true);
    }
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Card sx={{ maxWidth: 600 }}>
            <CardMedia component="img" height="10%" image={cover} alt="green iguana" />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {`¥${priceList[period]}`}
              </Typography>
              <Alert sx={{ m: 1 }} severity={qrUrl ? 'success' : 'info'}>
                {qrUrl
                  ? '用支付宝扫描二维码完成付款，支付成功后请至 Tradingview 查看授权。'
                  : '填写您的 Tradingview 昵称并点击购买获取支付宝二维码。'}
              </Alert>
              <Grid container justifyContent="center" alignItems="center" spacing={2}>
                <Grid item xs={12} md={8} sm={12}>
                  <Box
                    sx={{
                      '& > :not(style)': { m: 1, width: '25ch' },
                      mt: 2,
                    }}
                  >
                    <TextField
                      required
                      id="tv-name"
                      name="tvName"
                      label="Tradingview 昵称"
                      placeholder="Tradingview Name"
                      value={tvForm.tvName}
                      onChange={handleFormInput}
                      size="small"
                    />
                    <TextField
                      error={valid}
                      required
                      id="tv-name-confirm"
                      name="tvNameConfirm"
                      label="Tradingview 昵称 确认"
                      placeholder="Tradingview Name Confirm"
                      value={tvForm.tvNameConfirm}
                      onChange={handleFormInput}
                      size="small"
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sm={12}
                  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <Box sx={qrStyle}>{qrUrl && <QRCodeSVG value={qrUrl} />}</Box>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <LoadingButton
                loading={loading}
                onClick={handleBuyConfirm}
                variant="contained"
                size="medium"
                color="primary"
                startIcon={<Iconify icon="ant-design:alipay-circle-filled" />}
              >
                {L.buy[lang]}
              </LoadingButton>
              <FormControl sx={{ ml: 2 }}>
                <RadioGroup
                  row
                  aria-labelledby="time-radio-buttons-group-label"
                  name="period"
                  value={period}
                  onChange={handleChange}
                >
                  <FormControlLabel value={0} control={<Radio />} label={L.permanent[lang]} />
                  {/* <FormControlLabel value={1} control={<Radio />} label={L.threeMonth[lang]} />
                  <FormControlLabel value={2} control={<Radio />} label={L.halfyear[lang]} /> */}
                </RadioGroup>
              </FormControl>
            </CardActions>
          </Card>
        </Box>
      </Modal>
    </div>
  );
}
