import axios from 'axios';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
// @mui
import { Box, Card, CardHeader } from '@mui/material';
// components
import { createChart } from 'lightweight-charts';

import { CORS_PROXY } from '../../../_mock/env';

// ----------------------------------------------------------------------

AppFlowChart.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  pair: PropTypes.string,
};

export default function AppFlowChart({ title, subheader, pair, ...other }) {
  const chartContainerRef = useRef();

  useEffect(() => {
    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.parentElement.parentElement.offsetWidth - 20,
      height: 370,
      layout: {
        backgroundColor: '#212b36',
        textColor: 'rgba(255, 255, 255, 0.9)',
      },
      grid: {
        vertLines: {
          color: 'rgba(197, 203, 206, 0.2)',
        },
        horzLines: {
          color: 'rgba(197, 203, 206, 0.2)',
        },
      },
      localization: {
        dateFormat: 'yyyy/MM/dd',
        priceFormatter: (percentage) => `${percentage.toFixed(4)}%`,
      },
      timeScale: {
        timeVisible: true,
        secondsVisible: false,
      },
    });

    new ResizeObserver(() => {
      chart.resize(chartContainerRef.current.parentElement.parentElement.offsetWidth - 20, 370);
    }).observe(chartContainerRef.current.parentElement.parentElement);

    async function fetchData() {
      const result = await axios(`${CORS_PROXY}https://fapi.binance.com/fapi/v1/fundingRate?symbol=${pair}&limit=100`);

      const areaSeries = chart.addAreaSeries({
        topColor: 'rgba(33, 150, 243, 0.56)',
        bottomColor: 'rgba(33, 150, 243, 0.04)',
        lineColor: 'rgba(33, 150, 243, 1)',
        lineWidth: 2,
      });

      areaSeries.setData(
        result.data.map((funding) => ({
          time: funding.fundingTime / 1000,
          value: funding.fundingRate * 100,
        }))
      );
    }
    fetchData();
    return function cleanChart() {
      chart.remove();
    };
  }, [pair]);

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Box sx={{ p: 2, pb: 4 }} dir="ltr">
        <div ref={chartContainerRef} />
      </Box>
    </Card>
  );
}
