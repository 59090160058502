import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container } from '@mui/material';
// components
import Page from '../components/Page';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Privacy() {
  return (
    <Page title="RitMEX Privacy Policy">
      <Container>
        <ContentStyle sx={{ textAlign: 'left', alignItems: 'left' }}>
          <Typography variant="h3" paragraph>
            RitMEX Privacy Statement
          </Typography>

          <Typography variant="caption" paragraph>
            Effective date: September 1, 2022
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 2 }}>
            Thanks for entrusting RitMEX Inc. or RitMEX B.V. (“RitMEX”, “we”, "us" or "our") with your source code, your
            projects, and your personal data. This Privacy Statement explains our practices regarding the collection,
            use, and disclosure of your data, including any personal data we collect and process in connection with our
            website and any applications, software, products, and services provided by RitMEX, including any Beta
            Previews (collectively “Service”). All capitalized terms have their definition in RitMEX’s Terms of Service,
            unless otherwise noted here.
          </Typography>

          <Typography variant="h4" paragraph>
            The short version
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 2 }}>
            We use your personal information as this Privacy Statement describes. No matter where you are, where you
            live, or what your citizenship is, you have the same high standard of privacy protection when using RitMEX's
            products as all our users around the world, regardless of their country of origin or location. To see our
            Privacy Notice to residents of California, please go to RitMEX's Notice about the California Consumer
            Privacy Act or scroll down.
          </Typography>

          <Button to="/" size="large" variant="contained" component={RouterLink}>
            Go to Home
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  );
}
