// import { faker } from '@faker-js/faker';
// import { sample } from 'lodash';

// ----------------------------------------------------------------------

const indicators = [
  {
    id: 'RxZdOl68',
    cover: `/static/images/indicators/indicator_trendMasterCrypto.png`,
    name: 'Trend Master Crypto',
    price: 299,
    priceSale: null,
    status: 'sale',
  },
  {
    id: '1elJ85dr',
    cover: `/static/images/indicators/indicator_bollingerBandsTradingSignal.png`,
    name: 'Bollinger Bands Trading Signals',
    price: 299,
    priceSale: null,
    status: '',
  },
  {
    id: 'gbn7jCV1',
    cover: `/static/images/indicators/indicator_supplyDemand.png`,
    name: 'Supply/Demand',
    price: 299,
    priceSale: null,
    status: '',
  },
  {
    id: 'M4WCm7uK',
    cover: `/static/images/indicators/indicator_thomasDeMarkSequential.png`,
    name: 'Thomas DeMark Sequential Signals (Premium)',
    price: 299,
    priceSale: null,
    status: '',
  },
  {
    id: 'WY2ZUrob',
    cover: `/static/images/indicators/indicator_macdDivergenceSignals.png`,
    name: 'MACD Divergence Signals',
    price: 299,
    priceSale: null,
    status: 'new',
  },
  {
    id: 'XxrgfzcH',
    cover: `/static/images/indicators/indicator_binanceFunding.png`,
    name: 'Binance Funding Indicator Advanced',
    price: 299,
    priceSale: null,
    status: '',
  },
  {
    id: 'D3WaBxyD',
    cover: `/static/images/indicators/indicator_bitcoinBuySell.png`,
    name: 'Bitcoin Daily Buy & Sell Signal',
    price: 299,
    priceSale: null,
    status: '',
  },
];

// ----------------------------------------------------------------------

// const indicators = [...Array(24)].map((_, index) => {
//   const setIndex = index + 1;

//   return {
//     id: faker.datatype.uuid(),
//     cover: `/static/mock-images/indicators/indicator_${setIndex}.png`,
//     name: PRODUCT_NAME[index],
//     price: faker.datatype.number({ min: 4, max: 99, precision: 0.01 }),
//     priceSale: setIndex % 3 ? null : faker.datatype.number({ min: 19, max: 29, precision: 0.01 }),
//     colors:
//       (setIndex === 1 && PRODUCT_COLOR.slice(0, 2)) ||
//       (setIndex === 2 && PRODUCT_COLOR.slice(1, 3)) ||
//       (setIndex === 3 && PRODUCT_COLOR.slice(2, 4)) ||
//       (setIndex === 4 && PRODUCT_COLOR.slice(3, 6)) ||
//       (setIndex === 23 && PRODUCT_COLOR.slice(4, 6)) ||
//       (setIndex === 24 && PRODUCT_COLOR.slice(5, 6)) ||
//       PRODUCT_COLOR,
//     status: sample(['sale', 'new', '', '', '', '']),
//   };
// });

export default indicators;
