import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation, matchPath } from 'react-router-dom';
// material
import { Avatar, Box, Button, Drawer, Link, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// mock
import account from '../../_mock/account';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/Logo';
import NavSection from '../../components/NavSection';
import Scrollbar from '../../components/Scrollbar';
//
import Iconify from '../../components/Iconify';
import navConfig from './NavConfig';

import L from '../../utils/locale';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  lang: PropTypes.string,
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ lang, isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();

  const matchHome = matchPath(
    {
      path: '/dashboard/app',
      exact: true,
    },
    pathname
  );

  const loginAccount = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Logo />
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gap={1}>
          <Link component={RouterLink} underline="none" to={`/dashboard/app`}>
            <Typography variant="span" sx={{ color: matchHome ? 'text.success' : 'text.primary' }}>
              {L.home[lang]}
            </Typography>
          </Link>
          {'/'}
          <Link underline="none" href={`https://wallet.ritmex.one`} target="_blank">
            <Typography variant="span" sx={{ color: 'text.primary' }}>
              {L.wallet[lang]}
            </Typography>
          </Link>
          {'/'}
          <Link underline="none" href={`https://trade.ritmex.one`} target="_blank">
            <Typography variant="span" sx={{ color: 'text.primary' }}>
              {L.trade[lang]}
            </Typography>
          </Link>
        </Box>
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
            <Avatar src={account.photoURL} alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {loginAccount ? loginAccount.name : account.displayName}
              </Typography>
              {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {account.role}
              </Typography> */}
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      <NavSection lang={lang} navConfig={navConfig} />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5 }}>
        <Stack alignItems="center" spacing={2} sx={{ borderRadius: 2, position: 'relative' }}>
          {/* <Box
            component="img"
            src="/static/illustrations/illustration_avatar.png"
            sx={{ width: 100, position: 'absolute', top: -50 }}
          />

          <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
              Get more?
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              From only $69
            </Typography>
          </Box> */}

          <Button
            fullWidth
            href="https://www.okx.com/join/85046829"
            target="_blank"
            variant="contained"
            startIcon={<Iconify icon={'mdi:dice-five'} width={24} height={24} />}
          >
            {L.okxDiscount[lang]}
          </Button>

          <Button
            fullWidth
            href={`https://accounts.binance.com/${lang === 'cn' ? 'zh-CN' : 'en'}/register?ref=20768006`}
            target="_blank"
            variant="contained"
            color="warning"
            startIcon={<Iconify icon={'simple-icons:binance'} width={24} height={24} />}
          >
            {L.binanceDiscount[lang]}
          </Button>

          <Button
            fullWidth
            href={`https://partner.bitget.com/bg/MRSHJ6`}
            target="_blank"
            variant="contained"
            color="info"
            startIcon={<Iconify icon={'material-symbols:swap-horiz'} width={24} height={24} />}
          >
            {L.bitgetDiscount[lang]}
          </Button>

          <Button
            fullWidth
            href="https://defillama.com/yields?minTvl=100000000&maxTvl=&minApy=3&maxApy=&chain=Ethereum&chain=Arbitrum&chain=BSC"
            target="_blank"
            variant="contained"
            color="secondary"
            startIcon={<Iconify icon={'mdi:sheep'} width={24} height={24} />}
          >
            {L.selectedDefi[lang]}
          </Button>
        </Stack>
      </Box>
      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Box sx={{ textAlign: 'center' }}>
          <Typography gutterBottom variant="caption">
            &copy; {new Date().getFullYear()} RitMEX
          </Typography>
        </Box>
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
