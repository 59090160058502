import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
// material
import { Button, ClickAwayListener, IconButton, Input, InputAdornment, Slide } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled('div')(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  backgroundColor: `${alpha(theme.palette.background.default, 0.72)}`,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------
Searchbar.propTypes = {
  avaliablePairs: PropTypes.array,
  handleChangePair: PropTypes.func,
};

export default function Searchbar({ avaliablePairs, handleChangePair }) {
  const [isOpen, setOpen] = useState(false);
  const inputRef = useRef(null);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const searchPair = () => {
    const result = avaliablePairs.findIndex((o) =>
      o.pair.includes(inputRef.current.value.replaceAll(' ', '').toUpperCase())
    );
    // console.log('Search result: ', result);
    if (result > -1) {
      handleChangePair(result);
    }
    setOpen(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchPair();
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!isOpen && (
          <IconButton onClick={handleOpen}>
            <Iconify icon="eva:search-fill" width={20} height={20} />
          </IconButton>
        )}

        <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
          <SearchbarStyle>
            <Input
              inputRef={inputRef}
              autoFocus
              fullWidth
              disableUnderline
              placeholder="Search…"
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              }
              sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
              onKeyDown={handleKeyDown}
            />
            <Button variant="contained" onClick={searchPair}>
              Search
            </Button>
          </SearchbarStyle>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}
