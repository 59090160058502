import { RSI } from 'technicalindicators';

const avg = (data) => {
  let sum = 0;
  for (let i = 0; i < data.length; i += 1) {
    sum += parseFloat(data[i][4]);
  }
  return sum / data.length;
};

export function calculateSMA(data, count) {
  const result = [];
  for (let i = count - 1, len = data.length; i < len; i += 1) {
    const val = avg(data.slice(i - count + 1, i));
    result.push({
      time: data[i][0] / 1000,
      value: val,
      color: parseFloat(data[i][4]) > val ? '#0060CA' : '#FC345C',
    });
  }
  return result;
}

export function calculateLastSMA(data, count) {
  const sma = avg(data.slice(-Math.abs(count)));
  return parseFloat(sma.toFixed(2));
}

export function calculateLastMACD(data) {
  const macd = calculateLastSMA(data, 12) - calculateLastSMA(data, 26);
  return parseFloat(macd.toFixed(2));
}

export function calculateLastRSI(data, count) {
  const inputRSI = {
    values: data.map((ticker) => ticker[4]),
    period: count,
  };
  const result = RSI.calculate(inputRSI);

  return result[result.length - 1];
}
