import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { calculateLastMACD, calculateLastRSI, calculateLastSMA } from '../../../utils/indicators';
import L from '../../../utils/locale';
import { CORS_PROXY } from '../../../_mock/env';

const indicatorsData = [
  { name: 'MACD', value: 100, status: 'long' },
  { name: 'RSI', value: 70, status: 'short' },
  { name: 'SMA(60)', value: 20000, status: 'long' },
  { name: 'SMA(200)', value: 20000, status: 'long' },
];

AppTechnicalAnalysis.propTypes = {
  lang: PropTypes.string,
  pair: PropTypes.string,
  close: PropTypes.number,
  crowd: PropTypes.number,
};

export default function AppTechnicalAnalysis({ lang, pair, close, crowd }) {
  const [analysis, setAnalysis] = useState(indicatorsData);

  const [longTerm, setLongTerm] = useState({});
  const [shortTerm, setShortTerm] = useState({});
  const [crowdState, setCrowdState] = useState({});
  const [conclusion, setConclusion] = useState({});

  const [loading, setLoading] = useState(false);
  const [showConclusion, setShowConclusion] = useState(false);

  const handleClick = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setShowConclusion(true);
    }, 2000);
  };

  useEffect(() => {
    async function fetchData() {
      const dailyData = await axios(
        `${CORS_PROXY}https://api.binance.com/api/v3/klines?symbol=${pair}&interval=1d&limit=100`
      );

      const weeklyData = await axios(
        `${CORS_PROXY}https://api.binance.com/api/v3/klines?symbol=${pair}&interval=1w&limit=100`
      );

      const dailySMA60 = calculateLastSMA(dailyData.data, 60);

      const dailySMA200 = calculateLastSMA(dailyData.data, 200);

      const weeklySMA60 = calculateLastSMA(weeklyData.data, 60);

      const dailyMACD = calculateLastMACD(dailyData.data);

      const dailyRSI = calculateLastRSI(dailyData.data, 14);

      setAnalysis([
        { name: 'MACD', value: dailyMACD, status: dailyMACD > 0 ? 'long' : 'short' },
        { name: 'RSI', value: dailyRSI, status: dailyRSI < 50 ? 'long' : 'short' },
        { name: 'SMA(60)', value: dailySMA60, status: close > dailySMA60 ? 'long' : 'short' },
        { name: 'SMA(200)', value: dailySMA200, status: close > dailySMA200 ? 'long' : 'short' },
        // { name: 'Weekly SMA(60)', value: weeklySMA60, status: close > weeklySMA60 ? 'long' : 'short' },
      ]);

      setLongTerm(close < weeklySMA60 ? { cn: '熊市', en: 'bear' } : { cn: '牛市', en: 'bull' });
      setShortTerm(close < dailySMA60 ? { cn: '回调', en: 'pullback' } : { cn: '反弹', en: 'rebound' });

      let crowdText;
      if (crowd < 45) {
        crowdText = { cn: '空头较重', en: 'Short' };
      } else if (crowd < 55) {
        crowdText = { cn: '多空均衡', en: 'Neither side' };
      } else {
        crowdText = { cn: '多头较重', en: 'Long' };
      }

      setCrowdState(crowdText);

      let conclusionText;
      if (crowd < 45) {
        conclusionText = { cn: '警惕逼空行情', en: 'be wary of forcing the short market' };
      } else if (crowd < 55) {
        conclusionText = { cn: '区间震荡整理', en: 'price may move sideways' };
      } else {
        conclusionText = { cn: '注意下跌风险', en: 'pay attention to downside risk' };
      }
      setConclusion(conclusionText);

      setLoading(false);
      setShowConclusion(false);
    }
    fetchData();
  }, [pair, close, crowd]);

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {L.taTitle[lang]}
        </Typography>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>{L.indicator[lang]}</TableCell>
                <TableCell align="right">{L.value[lang]}</TableCell>
                <TableCell align="right">{L.status[lang]}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {analysis.map((indicator) => (
                <TableRow key={indicator.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {indicator.name}
                  </TableCell>
                  <TableCell align="right">{indicator.value}</TableCell>
                  <TableCell align="right">
                    {indicator.status === 'long' ? (
                      <Chip label={indicator.status} color="success" />
                    ) : (
                      <Chip label={indicator.status} color="error" />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ mt: 4 }}>
          <Typography paragraph>
            {lang === 'en' &&
              showConclusion &&
              `The current ${pair} market is in a ${longTerm.en} market trend from a long term perspective, and belongs to a ${shortTerm.en} market from a short term perspective.  ${crowdState.en} positions in futures contracts are heavy, ${conclusion.en}.`}
          </Typography>
          <Typography paragraph variant="body2">
            {lang === 'cn' &&
              showConclusion &&
              `当前${pair}市场从长线看处于${longTerm.cn}趋势中，短线看属于${shortTerm.cn}行情。期货合约仓位${crowdState.cn}，${conclusion.cn}。`}
          </Typography>
        </Box>
      </CardContent>
      <CardActions>
        <LoadingButton
          size="small"
          onClick={handleClick}
          loading={loading}
          variant="contained"
          disabled={showConclusion}
        >
          {L.analyze[lang]}
        </LoadingButton>
      </CardActions>
    </Card>
  );
}
