import axios from 'axios';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
// @mui
import { Box, Button, Card, CardHeader, Chip, IconButton } from '@mui/material';
// components
import { createChart } from 'lightweight-charts';

import Iconify from '../../../components/Iconify';
import { mergeAsks, mergeBids } from '../../../utils/formatNumber';
import { mobileAndTabletCheck } from '../../../utils/helper';

import { calculateSMA } from '../../../utils/indicators';

import { CORS_PROXY } from '../../../_mock/env';

import L from '../../../utils/locale';

// ----------------------------------------------------------------------

AppPriceChart.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  pair: PropTypes.string,
  precision: PropTypes.number,
  depth: PropTypes.number,
  lang: PropTypes.string,
};

export default function AppPriceChart({ title, subheader, pair, precision, depth, lang, ...other }) {
  const [timeFrame, setTimeFrame] = useState('4h');
  const [marketStatus, setMarketStatus] = useState('');
  const [enableHeatmap, setEnableHeatmap] = useState(false);
  const [showFunding, setShowFunding] = useState(true);
  const toggleHeatmap = () => {
    setEnableHeatmap(!enableHeatmap);
  };
  const toggleFunding = () => {
    setShowFunding(!showFunding);
  };
  const chartContainerRef = useRef();
  const cardRef = useRef();
  const chartInstance = useRef();
  useEffect(() => {
    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.parentElement.parentElement.offsetWidth - 20,
      height: chartContainerRef.current.parentElement.parentElement.offsetHeight - 100,
      rightPriceScale: {
        visible: true,
      },
      leftPriceScale: {
        visible: true,
      },
      layout: {
        backgroundColor: '#212b36',
        textColor: 'rgba(255, 255, 255, 0.9)',
      },
      grid: {
        vertLines: {
          color: 'rgba(197, 203, 206, 0.1)',
        },
        horzLines: {
          color: 'rgba(197, 203, 206, 0.1)',
        },
      },
      localization: {
        dateFormat: 'yyyy/MM/dd',
      },
      timeScale: {
        timeVisible: true,
        secondsVisible: false,
        rightOffset: 20,
      },
      watermark: {
        visible: true,
        fontSize: 48,
        horzAlign: 'center',
        vertAlign: 'center',
        color: 'rgba(0,0,0, 0.1)',
        text: 'RitMEX',
      },
    });

    new ResizeObserver(() => {
      chart.resize(
        chartContainerRef.current.parentElement.parentElement.offsetWidth - 20,
        chartContainerRef.current.parentElement.parentElement.offsetHeight - 100
      );
    }).observe(chartContainerRef.current.parentElement.parentElement);

    chartInstance.current = chart;

    let ws;

    async function fetchData() {
      const result = await axios(`${CORS_PROXY}https://api.binance.com/api/v3/depth?symbol=${pair}&limit=${depth}`);

      const weeklyData = await axios(
        `${CORS_PROXY}https://api.binance.com/api/v3/klines?symbol=${pair}&interval=1w&limit=1`
      );

      const monthlyData = await axios(
        `${CORS_PROXY}https://api.binance.com/api/v3/klines?symbol=${pair}&interval=1M&limit=1`
      );

      const priceData = await axios(
        `${CORS_PROXY}https://api.binance.com/api/v3/klines?symbol=${pair}&interval=${timeFrame}`
      );

      const ratioData = await axios(
        `${CORS_PROXY}https://fapi.binance.com/futures/data/globalLongShortAccountRatio?symbol=${pair}&period=${timeFrame}&limit=500`
      );

      const openInterestData = await axios(
        `${CORS_PROXY}https://fapi.binance.com/futures/data/openInterestHist?symbol=${pair}&period=${timeFrame}&limit=500`
      );

      const fundingRateData = await axios(
        `${CORS_PROXY}https://fapi.binance.com/fapi/v1/fundingRate?symbol=${pair}&limit=500`
      );

      const priceChange8H = priceData.data[priceData.data.length - 1][4] - priceData.data[priceData.data.length - 8][4];
      const ratioChange8H =
        ratioData.data[ratioData.data.length - 1].longAccount - ratioData.data[ratioData.data.length - 8].longAccount;
      const openInterestChange8H =
        openInterestData.data[openInterestData.data.length - 1].sumOpenInterest -
        openInterestData.data[openInterestData.data.length - 8].sumOpenInterest;

      const getMarketStatus = () => {
        if (priceChange8H > 0) {
          if (openInterestChange8H > 0) {
            if (ratioChange8H > 0) {
              return 'longTrend';
            }
            return 'shortSqueeze';
          }
          if (ratioChange8H > 0) {
            return 'shortStopLoss';
          }
          return 'longTakeProfit';
        }
        if (openInterestChange8H > 0) {
          if (ratioChange8H > 0) {
            return 'longSqueeze';
          }
          return 'shortTrend';
        }
        if (ratioChange8H > 0) {
          return 'shortTakeProfit';
        }
        return 'longStopLoss';
      };

      setMarketStatus(getMarketStatus());

      const support = mergeBids(result.data.bids, precision * 100);
      const resistance = mergeAsks(result.data.asks, precision * 100);

      const maxSupport = Object.keys(support).reduce((a, b) => (support[a] > support[b] ? a : b));

      const maxResistance = Object.keys(resistance).reduce((a, b) => (resistance[a] > resistance[b] ? a : b));

      const maxSupportLine = {
        price: maxSupport,
        color: 'rgba(0, 120, 255,0.8)',
        lineWidth: 1,
        lineStyle: 3,
        axisLabelVisible: true,
        // title: 'Support',
      };
      const maxResistanceLine = {
        price: maxResistance,
        color: 'rgba(190, 18, 56,0.8)',
        lineWidth: 1,
        lineStyle: 3,
        axisLabelVisible: true,
        // title: 'Resistance',
      };

      const weeklyOpenLine = {
        price: weeklyData.data[0][1],
        color: '#3A5BA0',
        lineWidth: 1,
        lineStyle: 0,
        axisLabelVisible: true,
        title: 'Weekly',
      };
      const monthlyOpenLine = {
        price: monthlyData.data[0][1],
        color: '#FFA500',
        lineWidth: 1,
        lineStyle: 0,
        axisLabelVisible: true,
        title: 'Monthly',
      };

      const series = chart.addCandlestickSeries({
        upColor: 'rgb(38,166,154)',
        downColor: 'rgb(255,82,82)',
        wickUpColor: 'rgb(38,166,154)',
        wickDownColor: 'rgb(255,82,82)',
        borderVisible: false,
        scaleMargins: {
          top: 0,
          bottom: 0.1,
        },
      });

      series.setData(
        priceData.data.map((price) => ({
          time: price[0] / 1000,
          open: price[1],
          high: price[2],
          low: price[3],
          close: price[4],
        }))
      );

      series.applyOptions({
        priceFormat: {
          type: 'price',
          precision: precision.toString().split('.')[1] ? precision.toString().split('.')[1].length : 2,
          minMove: precision.toString().split('.')[1] ? precision : 0.01,
        },
      });

      // SMA

      const smaData = calculateSMA(priceData.data, 60);

      const smaLine = chart.addLineSeries({
        color: 'rgba(4, 111, 232, 1)',
        lineWidth: 2,
        priceLineVisible: false,
        lastValueVisible: false,
      });

      smaLine.setData(smaData);

      if (enableHeatmap) {
        Object.entries(support).forEach(([key, value]) => {
          const priceLine = {
            price: key,
            color: `rgba(0, 120, 255,${(0.5 * value) / support[maxSupport]})`,
            lineWidth: 5,
            lineStyle: 3,
            axisLabelVisible: false,
            // title: `Price Line for ${key}`
          };

          series.createPriceLine(priceLine);
        });

        Object.entries(resistance).forEach(([key, value]) => {
          const priceLine = {
            price: key,
            color: `rgba(190, 18, 56,${(0.5 * value) / resistance[maxResistance]})`,
            lineWidth: 5,
            lineStyle: 3,
            axisLabelVisible: false,
            // title: `Price Line for ${key}`
          };

          series.createPriceLine(priceLine);
        });
      } else {
        series.createPriceLine(maxSupportLine);
        series.createPriceLine(maxResistanceLine);
      }

      if (weeklyData.data[0][1] !== monthlyData.data[0][1]) {
        series.createPriceLine(weeklyOpenLine);
      }
      series.createPriceLine(monthlyOpenLine);

      if (showFunding) {
        // Funding Rate History

        const fundingSeries = chart.addHistogramSeries({
          priceFormat: {
            type: 'price',
          },
          priceLineVisible: false,
          color: '#92ffc4',
          priceScaleId: '',
          lastValueVisible: false,
          scaleMargins: {
            top: 0.9,
            bottom: 0,
          },
        });

        fundingSeries.setData(
          fundingRateData.data.map((funding) => ({
            time: funding.fundingTime / 1000,
            value: funding.fundingRate * 100,
            color: funding.fundingRate * 100 > 0 ? '#92ffc4' : '#ea526f',
          }))
        );
      } else {
        // Long Short Ratio

        const longShortSeries = chart.addHistogramSeries({
          priceFormat: {
            type: 'percent',
          },
          priceLineVisible: false,
          color: 'rgba(0, 150, 136, 0.8)',
          priceScaleId: '',
          lastValueVisible: false,
          scaleMargins: {
            top: 0.9,
            bottom: 0,
          },
        });

        longShortSeries.setData(
          ratioData.data.map((ratio) => ({
            time: ratio.timestamp / 1000,
            value: ratio.longAccount * 100 - 50,
            color: ratio.longAccount * 100 - 50 > 0 ? 'rgba(0, 150, 136, 0.8)' : 'rgba(248, 116, 116, 0.8)',
          }))
        );
      }

      // Open Interest

      const openInterestSeries = chart.addLineSeries({
        title: 'OI',
        color: '#EEEEEE80',
        lineWidth: 1,
        priceScaleId: 'left',
        scaleMargins: {
          top: 0,
          bottom: 0.1,
        },
      });
      openInterestSeries.setData(
        openInterestData.data.map((openInterest) => ({
          time: openInterest.timestamp / 1000,
          value: openInterest.sumOpenInterest / 1000,
        }))
      );

      const initWS = () => {
        /* Live Websocket Subscribe */
        ws = new WebSocket(`wss://stream.binance.com/stream?streams=${pair.toLowerCase()}@kline_${timeFrame}`);

        // const apiCall = {
        //   method: 'SUBSCRIBE',
        //   params: [`${pair.toLowerCase()}@kline_${timeFrame}`],
        //   id: 1,
        // };

        ws.onopen = () => {
          // ws.send(JSON.stringify(apiCall));
          console.log(pair, 'Binance Websocket connected!');
        };

        ws.onclose = () => {
          console.log(pair, 'Binance Websocket closed!');
          // setTimeout(initWS, 3000);
        };

        ws.onmessage = (event) => {
          const json = JSON.parse(event.data);
          // console.log(json.data);
          try {
            if (json.data.e === 'kline') {
              // console.log(json.data);
              const nextTick = {
                time: json.data.k.t / 1000,
                open: json.data.k.o,
                high: json.data.k.h,
                low: json.data.k.l,
                close: json.data.k.c,
              };
              series.update(nextTick);
            }
          } catch (err) {
            console.log(err);
          }
        };
      };

      if (!mobileAndTabletCheck()) initWS();
    }

    fetchData();

    // const fullScreenChanged = () => {
    //   if (!document.fullscreenElement) {
    //     setTimeout(
    //       () =>
    //         chartInstance.current.applyOptions({
    //           height: chartContainerRef.current.parentElement.parentElement.offsetHeight - 100,
    //           width: chartContainerRef.current.parentElement.parentElement.offsetWidth - 20,
    //         }),
    //       100
    //     );
    //   } else {
    //     setTimeout(
    //       () =>
    //         chartInstance.current.applyOptions({
    //           height: chartContainerRef.current.parentElement.parentElement.offsetHeight - 100,
    //           width: chartContainerRef.current.parentElement.parentElement.offsetWidth - 20,
    //         }),
    //       100
    //     );
    //   }
    // };

    // const currentCardRef = cardRef.current;

    // currentCardRef.addEventListener('fullscreenchange', fullScreenChanged);

    return function cleanChart() {
      if (ws) {
        ws.close();
      }
      // currentCardRef.removeEventListener('fullscreenchange', fullScreenChanged);
      chart.remove();
    };
  }, [pair, precision, depth, timeFrame, enableHeatmap, showFunding]);

  const debugBase64 = (base64URL) => {
    const win = window.open();
    win.document.write(
      `<iframe src="${base64URL}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`
    );
  };

  const captureChart = () => {
    debugBase64(chartInstance.current.takeScreenshot().toDataURL());
  };

  const handleFullScreen = () => {
    if (!document.fullscreenElement) {
      cardRef.current.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  return (
    <Card ref={cardRef} {...other}>
      <CardHeader
        title={title}
        subheader={subheader}
        action={
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} columnGap={1}>
            <Chip label={L[marketStatus][lang]} variant="outlined" />
            <Button
              variant={timeFrame === '15m' ? 'contained' : 'text'}
              size="small"
              onClick={() => setTimeFrame('15m')}
            >
              15m
            </Button>
            <Button variant={timeFrame === '1h' ? 'contained' : 'text'} size="small" onClick={() => setTimeFrame('1h')}>
              1h
            </Button>
            <Button
              sx={{ display: { xs: 'none', sm: 'flex' } }}
              variant={timeFrame === '4h' ? 'contained' : 'text'}
              size="small"
              onClick={() => setTimeFrame('4h')}
            >
              4h
            </Button>
            <Button
              sx={{ display: { xs: 'none', sm: 'flex' } }}
              variant={timeFrame === '1d' ? 'contained' : 'text'}
              size="small"
              onClick={() => setTimeFrame('1d')}
            >
              1D
            </Button>
            <IconButton
              color={showFunding ? 'info' : 'default'}
              sx={{ display: { xs: 'none', sm: 'flex' } }}
              onClick={toggleFunding}
            >
              <Iconify icon="ant-design:fund-view-outlined" width={20} height={20} />
            </IconButton>
            <IconButton
              color={enableHeatmap ? 'info' : 'default'}
              sx={{ display: { xs: 'none', sm: 'flex' } }}
              onClick={toggleHeatmap}
            >
              <Iconify icon="svg-spinners:bars-fade" width={20} height={20} />
            </IconButton>
            <IconButton sx={{ display: { xs: 'none', sm: 'flex' } }} onClick={handleFullScreen}>
              <Iconify icon="bx:fullscreen" width={20} height={20} />
            </IconButton>
            <IconButton onClick={() => captureChart()}>
              <Iconify icon="ant-design:camera-outlined" width={20} height={20} />
            </IconButton>
          </Box>
        }
      />

      <Box sx={{ p: 2, pb: 1, minHeight: 420, maxHeight: 420 }} dir="ltr">
        <div ref={chartContainerRef} />
      </Box>
    </Card>
  );
}
