import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useOutletContext } from 'react-router-dom';
import L from '../../../utils/locale';

TradeForm.propTypes = {
  strategy: PropTypes.object,
  handleTradeForm: PropTypes.func,
};

export default function TradeForm({ strategy, handleTradeForm }) {
  const [lang] = useOutletContext();
  const { pair, stoploss, takeprofit, position } = strategy;

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {L.strategyDetails[lang]}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl variant="standard" sx={{ minWidth: 200 }}>
            <InputLabel id="pair-label">{L.pair[lang]}</InputLabel>
            <Select
              labelId="pair-label"
              id="pair"
              name="pair"
              value={pair}
              onChange={handleTradeForm}
              label={L.pair[lang]}
            >
              <MenuItem value={'BTCUSDT'}>BTCUSDT</MenuItem>
              <MenuItem value={'ETHUSDT'}>ETHUSDT</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="stoploss"
            name="stoploss"
            value={stoploss}
            onChange={handleTradeForm}
            label={L.stoploss[lang]}
            fullWidth
            variant="standard"
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="takeprofit"
            name="takeprofit"
            value={takeprofit}
            onChange={handleTradeForm}
            label={L.takeprofit[lang]}
            fullWidth
            variant="standard"
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="position"
            name="position"
            value={position}
            onChange={handleTradeForm}
            label={L.position[lang]}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="secondary" name="saveStrategy" value="yes" />}
            label={L.saveStrategy[lang]}
          />
        </Grid>
      </Grid>
    </>
  );
}
