import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import Apis from './pages/Apis';
//
// import Blog from './pages/Blog';
// import Order from './pages/Order';
import DashboardApp from './pages/DashboardApp';
import Indicators from './pages/Indicators';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Privacy from './pages/Privacy';
import Ranking from './pages/Ranking';
import Register from './pages/Register';
import Strategy from './pages/Strategy';
import Terms from './pages/Terms';
// import Trade from './pages/Trade';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'ranking', element: <Ranking /> },
        { path: 'indicator', element: <Indicators /> },
        // { path: 'trade', element: <Trade /> },
        { path: 'strategy', element: <Strategy /> },
        { path: 'apis', element: <Apis /> },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: 'privacy', element: <Privacy /> },
        { path: 'terms', element: <Terms /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
