import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useOutletContext } from 'react-router-dom';
import L from '../../../utils/locale';

ExchangeForm.propTypes = {
  account: PropTypes.object,
  handleExchangeForm: PropTypes.func,
};

export default function ExchangeForm({ account, handleExchangeForm }) {
  const [lang] = useOutletContext();

  const { apiKey, apiSecret, passPhrase, exchange } = account;

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {L.exchangeAPIConnection[lang]}
      </Typography>
      <Grid item xs={12}>
        <FormControl>
          <FormLabel id="exchange-radio-buttons-group-label">{L.exchange[lang]}</FormLabel>
          <RadioGroup
            row
            aria-labelledby="exchange-radio-buttons-group-label"
            name="exchange"
            value={exchange}
            onChange={handleExchangeForm}
          >
            <FormControlLabel value="okx" control={<Radio />} label="OKX" />
            <FormControlLabel value="binance" control={<Radio />} label="Binance" />
            <FormControlLabel value="else" control={<Radio />} label="Coming soon..." disabled />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="apiKey"
            name="apiKey"
            label="API Key"
            fullWidth
            variant="standard"
            value={apiKey}
            onChange={handleExchangeForm}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="apiSecret"
            name="apiSecret"
            label="API Secret"
            fullWidth
            variant="standard"
            value={apiSecret}
            onChange={handleExchangeForm}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="passPhrase"
            name="passPhrase"
            label="Passphrase (only for OKX)"
            fullWidth
            variant="standard"
            value={passPhrase}
            onChange={handleExchangeForm}
            disabled={exchange !== 'okx'}
          />
        </Grid>
        {/* <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="secondary" name="saveCard" value="yes" />}
            label="Remember API details for next time"
          />
        </Grid> */}
      </Grid>
    </>
  );
}
