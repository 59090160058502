// import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
// material
import { Container, Stack, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import { IndicatorList } from '../sections/@dashboard/indicators';
// mock
import INDICATORS from '../_mock/indicators';
import L from '../utils/locale';

// ----------------------------------------------------------------------

export default function Indicators() {
  // const [openFilter, setOpenFilter] = useState(false);

  // const handleOpenFilter = () => {
  //   setOpenFilter(true);
  // };

  // const handleCloseFilter = () => {
  //   setOpenFilter(false);
  // };

  const [lang] = useOutletContext();

  return (
    <Page title="Dashboard: Indicators">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          {L.indicator[lang]}
        </Typography>

        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            {/* <IndicatorFilterSidebar
              isOpenFilter={openFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
            />
            <IndicatorSort /> */}
          </Stack>
        </Stack>

        <IndicatorList lang={lang} indicators={INDICATORS} />
        {/* <ProductCartWidget /> */}
      </Container>
    </Page>
  );
}
