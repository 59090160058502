import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useOutletContext } from 'react-router-dom';
import L from '../../../utils/locale';

ResultForm.propTypes = {
  account: PropTypes.object,
  strategy: PropTypes.object,
};

export default function ResultForm({ account, strategy }) {
  const [lang] = useOutletContext();
  const { exchange, apiKey, apiSecret } = account;
  const { pair, stoploss, takeprofit, position } = strategy;
  const tvLinks = [
    {
      name: 'Webhook URL',
      desc: 'The webhook url you link to Tradingview.',
      link: 'https://gateway.ritmex.one/tradinghook',
    },
    {
      name: 'Open Long (Spot Buy)',
      desc: 'Message for your Tradingview Open Long alerts.',
      link: { exchange, pair, position, method: 'openLong', token: localStorage.getItem('token') },
    },
    {
      name: 'Close Long (Spot Sell)',
      desc: 'Message for your Tradingview Close Long alerts.',
      link: { exchange, pair, position, method: 'closeLong', token: localStorage.getItem('token') },
    },
    // {
    //   name: 'Open Short',
    //   desc: 'Message for your Tradingview Open Short alerts.',
    //   link: { exchange, pair, position, method: 'openShort', token: localStorage.getItem('token') },
    // },
    // {
    //   name: 'Close Short',
    //   desc: 'Message for your Tradingview Close Short alerts.',
    //   link: { exchange, pair, position, method: 'openShort', token: localStorage.getItem('token') },
    // },
  ];

  const protection = ['Stop Loss', stoploss, 'Take Profit', takeprofit, 'Position', position];
  const payments = [
    { name: 'Exchange', detail: exchange },
    { name: 'API Key', detail: apiKey },
    { name: 'API Secret', detail: apiSecret },
    // { name: 'Ordered date', detail: '04/2024' },
  ];

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {L.strategyLinks[lang]}
      </Typography>
      <List disablePadding>
        {tvLinks.map((tvLink) => (
          <ListItem key={tvLink.name} sx={{ py: 1, px: 0 }}>
            <ListItemText primary={tvLink.name} secondary={tvLink.desc} />
            <TextField
              id="outlined-read-only-input"
              label={tvLink.name}
              value={JSON.stringify(tvLink.link)}
              InputProps={{
                readOnly: true,
              }}
              sx={{ minWidth: 300 }}
            />
          </ListItem>
        ))}
      </List>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            {L.tradingInfo[lang]}
          </Typography>
          <Typography gutterBottom>BTCUSDT</Typography>
          <Typography gutterBottom>{protection.join(', ')}</Typography>
        </Grid>
        <Grid item container xs={12} sm={6}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            {L.exchangeDetails[lang]}
          </Typography>
          <Grid container>
            {payments.map((payment) => (
              <Grid container key={payment.name}>
                <Grid item xs={6}>
                  <Typography gutterBottom>{payment.name}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom sx={{ overflow: 'hidden' }}>
                    {payment.detail}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
