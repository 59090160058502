import { Button, Card, Typography } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import PropTypes from 'prop-types';
import { useState } from 'react';
import L from '../../../utils/locale';
import ApiModal from './ApiModal';

// ----------------------------------------------------------------------

ApiCard.propTypes = {
  lang: PropTypes.string,
  api: PropTypes.object,
  setLoading: PropTypes.func,
};

export default function ApiCard({ lang, api, setLoading }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { api_key: apiKey, exchange } = api;

  return (
    <Card>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {exchange.toUpperCase()}
        </Typography>
        <Typography variant="h5" component="div">
          {apiKey.slice(0, 5).concat('******')}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={handleOpen}>
          {L.check[lang]}
        </Button>
      </CardActions>
      <ApiModal lang={lang} open={open} handleClose={handleClose} api={api} setLoading={setLoading} />
    </Card>
  );
}
