import axios from 'axios';
import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
// @mui
// import { useTheme } from '@mui/material/styles';
import { Box, Container, Grid, Typography } from '@mui/material';
// components

import Page from '../components/Page';
// sections
import {
  AppFederalRatesHistory,
  AppFlowChart,
  AppNewsUpdate,
  AppPairList,
  AppPriceChart,
  AppTechnicalAnalysis,
  AppTradingviewCalendar,
  AppWidgetSummary,
} from '../sections/@dashboard/app';

import { CORS_PROXY } from '../_mock/env';

import L from '../utils/locale';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const [lang, pair, avaliablePairs, handleChangePair] = useOutletContext();
  const [price, setPrice] = useState(0);
  const [fearData, setFearData] = useState(0);
  const [crowdData, setCrowdData] = useState({});
  // const [compositeData, setCompositeData] = useState(0);
  const [eliteData, setEliteData] = useState({});

  const handleSelectPair = (event) => {
    handleChangePair(event.target.value);
  };

  useEffect(() => {
    let ignore = false;

    async function fetchData() {
      const bitcoinTicker = await axios(
        `${CORS_PROXY}https://api.binance.com/api/v3/ticker/price?symbol=${avaliablePairs[pair].pair}`
      );
      const fngIndex = await axios(`${CORS_PROXY}https://api.alternative.me/fng/`);

      const crowdInfo = await axios(
        `${CORS_PROXY}https://fapi.binance.com/futures/data/globalLongShortAccountRatio?symbol=${avaliablePairs[pair].pair}&period=1h&limit=1`
      );

      // const compositeInfo = await axios(`https://gateway.ritmex.one/crowd_index/${avaliablePairs[pair].pair}`);

      const eliteInfo = await axios(
        `${CORS_PROXY}https://fapi.binance.com/futures/data/topLongShortPositionRatio?symbol=${avaliablePairs[pair].pair}&period=1h&limit=1`
      );

      if (!ignore) {
        setPrice(bitcoinTicker.data.price);
        setFearData(fngIndex.data.data[0].value);
        setCrowdData(crowdInfo.data[0]);
        // setCompositeData(compositeInfo.data.crowd_index);
        setEliteData(eliteInfo.data[0]);
      }
    }

    fetchData();

    return () => {
      ignore = true;
    };
    // eslint-disable-next-line
  }, [pair]);

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <AppPairList lang={lang} pairs={avaliablePairs} current={pair} handleSelectPair={handleSelectPair} />

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              // handlechangepair={handleChangePair}
              title={avaliablePairs[pair].pair}
              total={+price}
              icon={`cryptocurrency:${avaliablePairs[pair].name}`}
              // iconstyle={{ cursor: 'hand' }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title={L.fng[lang]}
              total={+fearData}
              color="warning"
              icon={'emojione:face-screaming-in-fear'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title={L.crowdIndex[lang]}
              total={crowdData.longAccount * 100}
              // composite={compositeData * 100}
              color="success"
              icon={'mdi:leek'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title={L.eliteIndex[lang]}
              total={eliteData.longAccount * 100}
              color="secondary"
              icon={'akar-icons:people-group'}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppPriceChart
              title={L.chart[lang]}
              // subheader={`${avaliablePairs[pair].pair} ${L.mainChartLine[lang]}`}
              pair={avaliablePairs[pair].pair}
              precision={avaliablePairs[pair].precision}
              depth={avaliablePairs[pair].depth}
              lang={lang}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppFlowChart title={L.interestFlow[lang]} pair={avaliablePairs[pair].pair} />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTradingviewCalendar lang={lang} />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppFederalRatesHistory lang={lang} />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate lang={lang} title={L.newsUpdate[lang]} />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTechnicalAnalysis
              lang={lang}
              pair={avaliablePairs[pair].pair}
              close={+price}
              crowd={crowdData.longAccount * 100}
            />
          </Grid>

          <Grid item xs={12}>
            <Box>
              <Typography variant="body1" gutterBottom>
                {L.donate[lang]}
              </Typography>
              <Typography gutterBottom variant="body2">
                ETH: 0xea9c085cd659fe6eaf01717cd3705e9f39375926
              </Typography>
              <Typography gutterBottom variant="body2">
                BTC: 3EviiU6VnMVFFaycPsjKe9Dvdz9kiUXHWn
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
