import { useState, useEffect } from 'react';
import axios from 'axios';
// @mui
import PropTypes from 'prop-types';
import { Box, Stack, Link, Card, Button, Divider, Typography, CardHeader } from '@mui/material';
// utils
import { fToNow } from '../../../utils/formatTime';
// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';

import { CORS_PROXY } from '../../../_mock/env';
import L from '../../../utils/locale';

// ----------------------------------------------------------------------
const newsPlaceholder = [
  {
    categories: [{ id: 5, name: 'Conference' }],
    coins: [{ id: 'ethereum', coingecko_id: '', name: 'Ethereum', rank: 2, symbol: 'ETH' }],
    created_date: '2021-12-31T12:37:47Z',
    date_event: '2022-07-19T00:00:00Z',
    id: 100986,
    proof: 'https://d32bfp67k1q0s7.cloudfront.net/b884ec63aaea2b547179a6772e1e56cb.png',
    source: 'https://coinmarketcal.com/event/ethcc-5-conference-100986',
    title: { en: 'ETHCC 5 Conference' },
  },
];
AppNewsUpdate.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string,
  subheader: PropTypes.string,
  recentNews: PropTypes.array,
};

export default function AppNewsUpdate({ lang, title, subheader, ...other }) {
  const [recentNews, setRecentNews] = useState(newsPlaceholder);
  useEffect(() => {
    async function fetchData() {
      const result = await axios(`${CORS_PROXY}https://developers.coinmarketcal.com/v1/events`, {
        headers: {
          Accept: 'application/json',
          'x-api-key': 'AqDThWda0i3jYCR4b306K6GKYzn77cBd7kTyiLju',
        },
        params: {
          max: '10',
          showOnly: 'significant_events',
        },
      });
      // console.log(result.data.body);
      setRecentNews(result.data.body);
    }
    fetchData();
  }, []);
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Scrollbar>
        <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
          {recentNews && recentNews.map((news) => <NewsItem key={news.id} news={news} />)}
        </Stack>
      </Scrollbar>

      <Divider />

      <Box sx={{ p: 2, textAlign: 'right' }}>
        <Button
          href="https://calendar.bitbo.io/"
          target="_blank"
          size="small"
          color="inherit"
          endIcon={<Iconify icon={'eva:arrow-ios-forward-fill'} />}
        >
          {L.viewAll[lang]}
        </Button>
      </Box>
    </Card>
  );
}

// ----------------------------------------------------------------------

NewsItem.propTypes = {
  news: PropTypes.shape({
    categories: PropTypes.array,
    proof: PropTypes.string,
    source: PropTypes.string,
    date_event: PropTypes.string,
    title: PropTypes.object,
    coins: PropTypes.array,
  }),
};

function NewsItem({ news }) {
  const { proof, source, title, coins, categories, date_event: dateEvent } = news;

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Box component="img" alt={title.en} src={proof} sx={{ width: 48, height: 48, borderRadius: 1, flexShrink: 0 }} />

      <Box sx={{ minWidth: 240, flexGrow: 1 }}>
        <Link href={source} target="_blank" color="inherit" variant="subtitle2" noWrap>
          {title.en}
        </Link>

        <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
          {`${coins[0].fullname} - ${categories[0].name}`}
        </Typography>
      </Box>

      <Typography variant="caption" sx={{ pr: 3, flexShrink: 0, color: 'text.secondary' }}>
        {fToNow(dateEvent)}
      </Typography>
    </Stack>
  );
}
